import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/atlassian/pipelines/agent/build/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "contents"
    }}>{`Contents`}</h1>
    <p>{`This is only a guide and shows what components Progress Manufacturing uses and their properties (props).  These components may be added to any project with a pull request on the bitbucket repository.  New components and changes in styling should be reflected in this guide.`}</p>
    <p>{`Each component should use Material-UI's own styling solution.  For custom components CSS-in-JS through `}<a parentName="p" {...{
        "href": "https://www.styled-components.com/"
      }}>{`styled-components API`}</a>{` should be used so that each component is scoped locally.`}</p>
    <p>{`Included in the bitbucket repo are the following files and folders.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-text"
      }}>{`progress-ui/
    |--utils/
    |   |--theme.jsx
    |   |--layout.jsx
    |--components/
    |   |--alerts.jsx
    |   |--badges.jsx
    |   |--breadcrumb.jsx
    |   |--buttons.jsx
    |   |--cards.jsx
    |   |--carousel.jsx
    |   |--collapse.jsx
    |   |--dropdowns.jsx
    |   |--forms.jsx
    |   |--heros.jsx
    |   |--inputs.jsx
    |   |--lists.jsx
    |   |--modal.jsx
    |   |--navbar.jsx
    |   |--navs.jsx
    |   |--offcanvas.jsx
    |   |--pagination.jsx
    |   |--progress.jsx
    |   |--scrollspy.jsx
    |   |--spinners.jsx
    |   |--toasts.jsx
    |   |--badges.jsx
    |   |--tooltips.jsx
    |--images/
        |--logos/
        |   |--equalizer.svg
        |   |--fastway.svg
        |   |--progressmfg.svg
        |--social-icons
            |--round/
            |    |--facebook.svg
            |    |--instagram.svg
            |    |--youtube.svg
            |    |--linkedin.svg
            |    |--twitter.svg
            |--square/
            |    |--facebook.svg
            |    |--instagram.svg
            |    |--youtube.svg
            |    |--linkedin.svg
            |    |--twitter.svg
            |--opaque/
                 |--facebook.svg
                 |--instagram.svg
                 |--youtube.svg
                 |--linkedin.svg
                 |--twitter.svg
`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      